import React from "react";
import { Link } from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import RSVPForm from "../components/rsvp-form.js";

import "./rsvp.scss";

class RSVPPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      error: false
    };
  }
  formSubmittedHandler(success) {
    console.log("formSubmittedHanlder", success);
    if (!success) {
      this.setState({
        submitted: true,
        error: true
      });
    } else {
      this.setState({
        submitted: true,
        error: false
      });
    }
  }
  render() {
    let form = null;
    if (this.state.submitted) {
      form = (
        <Row className="thanks">
          <Col>
            <h3>Thanks!</h3>
          </Col>
        </Row>
      );
    } else {
      form = (
        <RSVPForm onSubmitted={success => this.formSubmittedHandler(success)} />
      );
    }
    let errorClass = '';
    if (this.state.error == true) {
      errorClass = "error";
    }
    return (
      <Layout page="rsvp">
        <SEO title="RSVP & Contact" />

        <Row className="page-header">
          <Col sm />
          <Col className="col-sm-10">
            <h2>RSVP & Contact</h2>
          </Col>
          <Col sm />
        </Row>

        <Row className={"rsvp-content " + errorClass}>
          <Col sm />
          <Col className="col-sm-8">{form}</Col>
          <Col sm />
        </Row>

        <Row className="page-content rsvp-contact">
          <Col sm />
          <Col className="col-sm-10">
            <p>
              Please feel free to get in touch lest anything come up:&nbsp;
              <a href="mailto:awmmcb@fastmail.com">awmmcb@fastmail.com</a>
            </p>
          </Col>
          <Col sm />
        </Row>

        <PageFooter />
      </Layout>
    );
  }
}

export default RSVPPage;
