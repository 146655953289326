import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import classNames from "classnames";

const InputFeedback = ({ error }) =>
      error ? <div className={classNames("errors")}>{error}</div> : null;

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
    return (
        <Col>
      <Form.Check
        name={name}
        label={label}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("")}
        {...props}
            />
        </Col>
  );
};


const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <Form.Group className={classes}>
      <fieldset>
        <legend>{label}</legend>
        <Row>
      {children}
      </Row>
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </Form.Group>
  );
};

export { RadioButton, RadioButtonGroup };
