import PropTypes from "prop-types";
import React from "react";

import { Formik, Field } from "formik";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { RadioButton, RadioButtonGroup } from "./utils/forms.js";

import "./rsvp-form.scss";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const RSVPForm = ({ onSubmitted }) => (
  <div className="component-rsvp-form">
    <Row className="header">
      <Col className="col-12">
        <h3>Répondez s'il vous plaît!</h3>
      </Col>
    </Row>
    <Formik
      initialValues={{
        name_one: "",
        name_two: "",
        email: "",
        rsvp: "",
        welcome_dinner: "",
        "bot-field": "",
        "form-name": "rsvp_form"
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => {
        let errors = {};
        console.log(values);
        if (!values.name_one) {
          errors.name_one = "Please enter your name.";
        }
        if (!values.email) {
          errors.email = "Please enter your email.";
        }
        if (values.rsvp == null) {
          errors.rsvp = "Please RSVP.";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/rsvp/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "rsvp_form", ...values })
        })
          .then(() => {
            onSubmitted(true);
            setSubmitting(false);
          })
          .catch(error => {
            onSubmitted(false);
            alert(error);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Row className="form">
          <Col>
            <form
              onSubmit={handleSubmit}
              name="rsvp_form"
              data-netlify={true}
              data-netlify-honeypot="bot-field"
              action="/success"
            >
              <Form.Group>
                <Form.Control
                  id="name_one"
                  autoComplete="off"
                  type="text"
                  placeholder="Guest One"
                  name="name_one"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={!!errors.name_one}
                />
                <div className="errors">
                  {errors.name_one && touched.name_one && errors.name_one}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  id="name_two"
                  autoComplete="nerp"
                  type="text"
                  placeholder="Guest Two"
                  name="name_two"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name_two}
                  isInvalid={!!errors.name_two}
                />
                <div className="errors">
                  {errors.name_two && touched.name_two && errors.name_two}
                </div>
              </Form.Group>

              <Form.Group controlId="email">
                {/* <Form.Label>Email</Form.Label> */}
                <Form.Control
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={!!errors.email}
                />
                <div className="errors">
                  {errors.email && touched.email && errors.email}
                </div>
              </Form.Group>

              <RadioButtonGroup
                id="rsvp"
                className=""
                label="Wedding?"
                value={values.rsvp}
                error={errors.rsvp}
                touched={touched.rsvp}
              >
                <p>It'll be fun - we promise!</p>
                <Field
                  component={RadioButton}
                  name="rsvp"
                  id="rsvp_accept"
                  label="Yes, can't wait!"
                />
                <Field
                  component={RadioButton}
                  name="rsvp"
                  id="rsvp_decline"
                  label="Sadly, I decline."
                />
              </RadioButtonGroup>

              <RadioButtonGroup
                id="welcome_dinner"
                className=""
                label="Welcome Dinner?"
                value={values.welcome_dinner}
                error={errors.welcome_dinner}
                touched={touched.welcome_dinner}
              >
                <p>Not sure yet? Take your best guess!</p>
                <Field
                  component={RadioButton}
                  name="welcome_dinner"
                  id="welcome_accept"
                  label="I'll come hungry!"
                />
                <Field
                  component={RadioButton}
                  name="welcome_dinner"
                  id="welcome_decline"
                  label="No food for me."
                />
              </RadioButtonGroup>

              <Row className="footer">
                <Col>
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Col>
              </Row>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="rsvp_form" />
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  </div>
);

RSVPForm.propTypes = {};

RSVPForm.defaultProps = {};

export default RSVPForm;
